<template>
  <div>
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />
    <section class="ftco-section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6 text-center mb-5">
            <div class="heading-section text-dark p-2">
              {{$t("useraut.slogan1")}}
            </div>
            <a :href="SITE_URL" class="urlclass">{{ SITE_URL }}</a>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-7 col-lg-5">
            <div class="login-wrap p-4 p-md-5">
              <div class="d-flex">
                <div class="w-100">
                  <h3 class="mb-4 text-dark d-flex align-items-center justify-content-center">{{$t("useraut.forgotpassword")}}</h3>
                </div>
              </div>
              <form action="#" class="login-form" v-on:submit.prevent="password">
                <div class="form-group">
                  <div class="
                      icon
                      d-flex
                      align-items-center
                      justify-content-center
                    ">
                    <span class="fa fa-envelope"></span>
                  </div>
                  <input type="email" class="form-control rounded-left" name="email" v-model="email" :placeholder="$t('useraut.email')"
                    autocomplete="nope" required />
                  <small v-if="!$v.email.email" class="error form-text text-danger">{{$t("useraut.validaddress")}}</small>
                </div>
                <div class="form-group d-flex align-items-center">
                  <div class="w-100 d-flex justify-content-end">
                    <button type="submit" class="btn btn-primary rounded submit">
                      {{$t("useraut.sendemail")}}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import DataService from "../services/DataService";
  import { email, required } from "vuelidate/lib/validators";
  export default {
    name: "Forgot-Password",
    metaInfo: function() {
    return {
      title: this.$t("metatag.titledefault"),
      titleTemplate: "%s | "+this.$t("metatag.titleDetailHome"),
      meta: [
      { name: 'description', content:  this.$t("metatag.description") },
      { name: 'keywords', content:  this.$t("metatag.keywords") }
      ]
    }
  },
    data() {
      return {
        email: "",
        term: "",
        browser: "",
        browserLang: "",
        platform: "",
        SITE_URL: process.env.VUE_APP_SITE_URL,
        aktifDil: this.$session.get("lang")
      };
    },
    validations: {
      email: {
        required,
        email,
      },
    },
    methods: {
      sessionKontrol() {
        if (this.$session.get("logtoken")) {
          window.location.href = `/${this.$session.get("lang")}/dashboard`;
        }
      },
      userActivity() {
        DataService.userActivity().then((response) => {
          this.term = response.data.ip;
        });
        this.browser = navigator.userAgent;
        this.browserLang = navigator.language;
        this.platform = navigator.platform;
      },
      async createToken(
        data0 = "null",
        data1 = "null",
        data2 = "null",
        data3 = "null",
        data4 = "null",
        data5 = "null",
        data6 = "null",
        data7 = "null",
        data8 = "null",
        data9 = "null",
        data10 = "null"
      ) {
        var send = [];
        await DataService.veriSifrele([
          data0,
          data1,
          data2,
          data3,
          data4,
          data5,
          data6,
          data7,
          data8,
          data9,
          data10,
        ]).then((response) => {
          send.push(response.data);
        });
        return send;
      },
      async password() {
        if (this.email != "") {
          if (this.$v.$invalid === false) {
            DataService.forgotpassword({
              token: await this.createToken(this.email, this.aktifDil),
              activity: await this.createToken(
                this.term,
                this.browser,
                this.browserLang,
                this.platform
              ),
            })
              .then((response) => {
                if (!response.data.error && response.data.status == "ok") {
                  this.$vToastify.setSettings({
                    position: "bottom-right",
                    theme: "light",
                    successDuration: 10000,
                  });
                  this.$vToastify.success(this.$t('useraut.passwordreset'));
                  this.$router.push(`/${this.$session.get("lang")}/login`);
                } else {
                  this.$vToastify.setSettings({
                    position: "bottom-right",
                    theme: "light",
                  });
                  this.$vToastify.warning(
                    "",
                    this.$t("message." + response.data.error)
                  );
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            this.$vToastify.setSettings({
              position: "bottom-right",
              theme: "light",
            });
            this.$vToastify.warning(this.$t('useraut.errorform'));
          }
        } else {
          this.$vToastify.setSettings({
            position: "bottom-right",
            theme: "light",
          });
          this.$vToastify.warning(this.$t('useraut.blankfield'));
        }
      },
    },
    mounted() {
      this.userActivity();
      this.sessionKontrol();
    },
  };
</script>
<style lang="" scoped src="../assets/css/login.css">
</style>