<template>
  <div>
    <PasswordForgot></PasswordForgot>
  </div>
</template>

<script>
// @ is an alias to /src
import PasswordForgot from '@/components/PasswordForgot.vue'

export default {
  name: 'PasswordForgotview',
  components: {
    PasswordForgot,
  }
}
</script>
